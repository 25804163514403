import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Show from "../components/Show";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const Index = ({ data }) => {
	const { allStoryblokEntry = {} } = data || {};
	const { edges = [] } = allStoryblokEntry;

	return (
		<Fragment>
			<SEO title="Ben R Clement" />
			<Layout>
				{edges.map(({ node }) => {
					const { content: stringContent, uuid, slug } = node;
					const blok = JSON.parse(stringContent);

					return (
						<div key={uuid}>
							<Show blok={blok} slug={slug} />
						</div>
					);
				})}
			</Layout>
		</Fragment>
	);
};

Index.defaultProps = {
	data: null,
};

Index.propTypes = {
	data: PropTypes.shape(),
};

export default Index;

export const pageQuery = graphql`
	query IndexQuery {
		allStoryblokEntry(
			sort: { fields: [position], order: ASC }
			filter: { full_slug: { regex: "/shows//" } }
		) {
			edges {
				node {
					id
					name
					created_at
					uuid
					slug
					full_slug
					content
					is_startpage
					parent_id
					group_id
				}
			}
		}
	}
`;
